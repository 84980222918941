import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { githubuserAPI } from "../http-common";
import PropagateLoader from "react-spinners/PropagateLoader";
import Popup from 'reactjs-popup';

import { useTable, useSortBy, useFilters } from "react-table";

import "../styles/Content.css";
import "../styles/GithubWidget.css";




const GithubUser = (props) => {
  
    /***
     *     __  ___      ___  ___  __  
     *    /__`  |   /\   |  |__  /__` 
     *    .__/  |  /~~\  |  |___ .__/ 
     *                                
     */

    const [isLoaded, setIsLoaded] = React.useState(false); // Indikator ob Daten geladen wurden.
    const [hasError, setHasError] = React.useState(false); // Indikator ob Fehler vorliegen
    const [githubData, setgithubData] = React.useState([]);
    const [githubEditorVisible, setGithubEditorVisible] = React.useState(false);
    const [githubUserStatus, setGithubUserStatus] = React.useState("existing"); 
    const [githubUserId, setGithubUserId] = React.useState(""); 
    const [githubUserData, setgithubUserData] = React.useState({ 
      id: 0, 
      comment: "none",
      github: "none",
      lastchange: "never",
      ldap_login: "none",
      mail: "none",
      surname: "none",
      status: "none",
      team: "none",
      given_name: "none"
    });
  

    /***
     *    TABLE
     *                                
     */
    // Custom component to render Genres 
    const StatusCheck = ({ row, values }) => {
      // Loop through the array and create a badge-like component instead of a comma-separated string

      if(values === "check"){
        return (
          <>
          <span className="githubWidget--checkStatus">
          {values}
          </span>
          </>
        );
      }else if(values === "offen"){
        return (
          <>
          <span className="githubWidget--offenStatus">
          {values}
          </span>
          </>
        );
      } else {
        return (
          <>
          <span className="githubWidget--normalStatus">
            {values}
          </span>
          </>
        );    
      }
      
    };

    // Define the useLocalStorage function
    function useLocalStorage(key, defaultValue) {
      const [state, setState] = React.useState(
        () => JSON.parse(window.localStorage.getItem(key)) || defaultValue
      );

      React.useEffect(() => {
        window.localStorage.setItem(key, JSON.stringify(state));
      }, [key, state]);

      return [state, setState];
    }

    /**
     * Tabelle für die Github Userverwaltung
     * @returns
     */
    function Table({ data }) {
      /**
       * Input-Element für die Filterung der Tabelle
       *
       * @param {*} column
       * @returns
       */
      const ColumnFilter = ({ column }) => {
        const { filterValue, setFilter } = column;
        return (
          <div className="github-Table-SearchWrapper">
            <input
              className="github-Table-SearchInput"
              value={filterValue || ""}
              onChange={(e) => setFilter(e.target.value)}
              onClick={(e) => e.stopPropagation()} // Hier wird das Klickereignis gestoppt
              placeholder="Suche..."
            />
            <div
              className="github-Table-SearchReset"
              onClick={(e) => {
                e.stopPropagation(); // Hier wird das Klickereignis gestoppt
                setFilter("");
              }}
            >
              <FontAwesomeIcon icon="fa-solid fa-arrow-rotate-left" />
            </div>
          </div>
        );
      };

      const columns = React.useMemo(
        () => [
          {
            Header: "ID",
            accessor: "id",
            Filter: ColumnFilter,
          },      
          {
            Header: "LDAP Login",
            accessor: "ldap_login",
            Filter: ColumnFilter,
            
            sortType: (rowA, rowB, columnId) => {
              const valueA = rowA.values[columnId] ? rowA.values[columnId].toLowerCase() : '';
              const valueB = rowB.values[columnId] ? rowB.values[columnId].toLowerCase() : '';
              return valueA.localeCompare(valueB);
            },
          },
          {
            Header: "GitHub Login",
            accessor: "github_login",
            Filter: ColumnFilter,
            
            sortType: (rowA, rowB, columnId) => {
              const getValue = (value) => value ? value.toLowerCase() : "";

              const valueA = getValue(rowA.values[columnId]);
              const valueB = getValue(rowB.values[columnId]);
              return valueA.localeCompare(valueB);
            },
          },
          {
            Header: "Vorname",
            accessor: "given_name",
            Filter: ColumnFilter,
            
            sortType: (rowA, rowB, columnId) => {
              const getValue = (value) => value ? value.toLowerCase() : "";

              const valueA = getValue(rowA.values[columnId]);
              const valueB = getValue(rowB.values[columnId]);
              return valueA.localeCompare(valueB);
            },
          },
          {
            Header: "Nachname",
            accessor: "surname",
            Filter: ColumnFilter,
            defaultSortDesc: false,
            sortType: (rowA, rowB, columnId) => {
              const getValue = (value) => value ? value.toLowerCase() : "";

              const valueA = getValue(rowA.values[columnId]);
              const valueB = getValue(rowB.values[columnId]);
              return valueA.localeCompare(valueB);
            },
          },
          {
            Header: "E-Mail",
            accessor: "mail",
            Filter: ColumnFilter,
            
            sortType: (rowA, rowB, columnId) => {
              const getValue = (value) => value ? value.toLowerCase() : "";

              const valueA = getValue(rowA.values[columnId]);
              const valueB = getValue(rowB.values[columnId]);
              return valueA.localeCompare(valueB);
            },
          },
          {
            Header: "Zugehörigkeit",
            accessor: "team",
            Filter: ColumnFilter,
            /*
            sortType: (rowA, rowB, columnId) => {
              const valueA = rowA.values[columnId].toLowerCase();
              const valueB = rowB.values[columnId].toLowerCase();
              return valueA.localeCompare(valueB);
            },*/
          },
          {
            Header: "Status",
            accessor: "status",
            Filter: ColumnFilter,
            sortType: (rowA, rowB, columnId) => {
              const valueA = rowA.values[columnId].toLowerCase();
              const valueB = rowB.values[columnId].toLowerCase();
              return valueA.localeCompare(valueB);
            },
            Cell: ({ row, value }) => <StatusCheck row={row} values={value} />
          },
          {
            Header: "Bemerkung",
            accessor: "comment",
            Filter: ColumnFilter,
            /*
            sortType: (rowA, rowB, columnId) => {
              const valueA = rowA.values[columnId].toLowerCase();
              const valueB = rowB.values[columnId].toLowerCase();
              return valueA.localeCompare(valueB);
            },*/
          },
          {
            Header: "Letzte Änderung",
            accessor: "updated",
            Filter: ColumnFilter
            /*
            sortType: (rowA, rowB, columnId) => {
              const valueA = rowA.values[columnId].toLowerCase();
              const valueB = rowB.values[columnId].toLowerCase();
              return valueA.localeCompare(valueB);
            },*/
          },
          {
            Header: "Edit",
            Cell: ({ row, value }) => (
                <div className="githubWidget--rowBTN" onClick={() => githubUserEditor(row)}><FontAwesomeIcon icon="fa-solid fa-pen-to-square" />{value}</div>
            ),
          },            
          {
            Header: "Delete",
            Cell: ({ row, value }) => (
                <div className="githubWidget--rowBTNdelete" onClick={() => deleteGithubUser(row)}><FontAwesomeIcon icon="fa-solid fa-trash" />{value}</div>
            ),
          },                  
        ],
        []
      );

      // Use the useLocalStorage function in your useTable hook
      const [filters, setFilters] = useLocalStorage('filters', []);
      const [sortBy, setSortBy] = useLocalStorage('sortBy', [{ id: 'surname', desc: false }]);


      const { 
        getTableProps, 
        getTableBodyProps, 
        headerGroups, 
        rows, 
        prepareRow, 
        state: { filters: currentFilters, sortBy: currentSortBy },
         }
        =
        useTable({columns, data, initialState: { hiddenColumns: ["id"],sortBy, filters }}, useFilters, useSortBy);

        React.useEffect(() => {
          setFilters(currentFilters);
          setSortBy(currentSortBy);
        }, [currentFilters, setFilters, currentSortBy, setSortBy]);

      return (
        <table className="github-table" {...getTableProps()}>
          <thead className="github-table-head">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    className="github-tableHead"
                    {...column.getHeaderProps(column.getSortByToggleProps())} // Hinzufügen der Sortierfunktion
                  >
                    {column.render("Header")}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FontAwesomeIcon
                            className="githubWidget-arrow"
                            icon="fa-solid fa-arrow-down"
                          />
                        ) : (
                          <FontAwesomeIcon
                            className="githubWidget-arrow"
                            icon="fa-solid fa-arrow-up"
                          />
                        )
                      ) : (
                        ""
                      )}
                    </span>
                    <div>{column.canFilter ? column.render("Filter") : null}</div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);

              if(row.values.status === "check"){
                return (
                  <tr className="github-tableRowRED" {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td className="github-tableCell" {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              }else if(row.values.status === "offen"){
                return (
                  <tr className="github-tableRowYELLOW" {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td className="github-tableCell" {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              } else {
                return (
                  <tr className="github-tableRow" {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td className="github-tableCell" {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              }

            })}
          </tbody>
        </table>
      );
    }

  
    /***
     *          __   ___     ___  ___  ___  ___  __  ___  __  
     *    |  | /__` |__     |__  |__  |__  |__  /  `  |  /__` 
     *    \__/ .__/ |___    |___ |    |    |___ \__,  |  .__/ 
     *                                                        
     */

    const fetchData = async () => {
      const idToken = await props.receiveIDToken();
      const headers = { Authorization: idToken };

      githubuserAPI
        .get("/api/user", { headers })
        .then((res) => {
          const result = {
            status: res.status + "-" + res.statusText,
            headers: res.headers,
            data: res.data,
          };

          setgithubData(result.data);
          setIsLoaded(true);
        })
        .catch((error) => {
          const errorMsg = error.name + ": " + error.message;
          console.log(errorMsg);
          //props.handleNotify({ type: "alert", message: errorMsg });
        });
    };

    React.useEffect(() => {
      setIsLoaded(true);

      

      // call the function once use effect run
      fetchData();
      setIsLoaded(true);
    }, []);
    
    
    /***
     *     ___            __  ___    __        __  
     *    |__  |  | |\ | /  `  |  | /  \ |\ | /__` 
     *    |    \__/ | \| \__,  |  | \__/ | \| .__/ 
     *                                             
     */

    const saveGithubUser = async () => {  
     
      // User JSON erstellen
      const userData = JSON.stringify({
        ldap_login: ldap_loginRef.current.value,
        github_login: githubRef.current.value,
        given_name: given_nameRef.current.value,
        surname: surnameRef.current.value,
        mail: mailRef.current.value,
        team: teamRef.current.value,
        comment: commentRef.current.value,
        status: statusRef.current.value,
        id: githubUserData.id
      });

      if(githubUserStatus === "new"){

        const githubUser = "/api/user/";
        const idToken = await props.receiveIDToken();
        const headers = { 'Authorization': idToken };
          
        githubuserAPI.post(githubUser, userData, {headers})
          .then(response => {
            props.handleNotify({type: 'success', message: "Der User wurde gespeichert!"})
            setGithubUserId("")
            setGithubEditorVisible(false);  
            fetchData();          
          })
          .catch(function (error) {
            console.error(error.response.statusText);
            const errorText = error.response.statusText;
            const userDataObject = JSON.parse(userData);
                                  
            if(error.response.status === 422){
              props.handleNotify({ type: "alert", message: "Anlegen ist fehlgeschlagen. Der GitHub-User \""+userDataObject.github_login+"\" existiert nicht, ist schon in der Organisation vorhanden oder es sind keine freien Seats mehr vorhanden!" });
            } else if(error.response.status === 403){
              //props.handleNotify({ type: "alert", message: error.response.data });
              props.handleNotify({ type: "alert", message: "Github User existiert schon in der Tabelle!" });
            } 
            else {
              props.handleNotify({ type: "alert", message: "Das Speichern ist fehlgeschlagen." });
              fetchData();
            }
          });          
          
      } else {
        const githubUser = "/api/user/"+githubUserId;

        const idToken = await props.receiveIDToken();
        const headers = { 'Authorization': idToken };

        githubuserAPI.put(githubUser, userData, {headers})
          .then(response => {
            console.log(response);  
            props.handleNotify({type: 'success', message: "Der User wurde gespeichert!"})
            setGithubUserId("")
            setGithubEditorVisible(false);            
            fetchData();
          })
          .catch(function (error) {
            console.error(error);
            props.handleNotify({ type: "alert", message: "Das Speichern ist fehlgeschlagen." });
            fetchData();
          }); 

      }

    }


    const deleteGithubUser = async (row) => {  
     
      const githubUser = "/api/user/"+row.values.github_login;
      const idToken = await props.receiveIDToken();
      const headers = { 'Authorization': idToken };
        
      githubuserAPI.delete(githubUser, {headers})
        .then(response => {
          console.log(response);  
          props.handleNotify({type: 'success', message: "Der User wurde gelöscht!"})
          setGithubUserId("")  
          fetchData();
        })
        .catch(function (error) {
          console.error(error.response.status);
          if(error.response.status === 404){
            props.handleNotify({ type: "alert", message: "User konnte nicht gelöscht werden, da er nicht in der Orga vorhanden ist!" });
            fetchData();
          }
          else{
            props.handleNotify({ type: "alert", message: "Das Löschen ist fehlgeschlagen." });
            fetchData();
          }
        });    
    }


    function githubUserEditor(userInfo){
    
      if(userInfo !== "new"){
        setGithubUserStatus("existing")
        setGithubUserId(userInfo.values.github_login)
        setgithubUserData(prevState => ({
          ...prevState,
          id: userInfo.values.id,
          comment: userInfo.values.comment,
          github_login: userInfo.values.github_login,
          updated: userInfo.values.updated,
          ldap_login: userInfo.values.ldap_login,
          mail: userInfo.values.mail,
          surname: userInfo.values.surname,
          status: userInfo.values.status,
          team: userInfo.values.team,
          given_name: userInfo.values.given_name
        }));
      } else {
        setGithubUserStatus("new")
        setgithubUserData(prevState => ({
          ...prevState,
          id: 'new',
          comment: "",
          github_login: "",
          updated: "",
          ldap_login: "",
          mail: "",
          surname: "",
          status: "",
          team: "",
          given_name: ""
        }));
      }
      

     setGithubEditorVisible(true);
    
    }

    const ldap_loginRef = React.useRef();
    const githubRef = React.useRef();
    const given_nameRef = React.useRef();
    const surnameRef = React.useRef();
    const mailRef = React.useRef();
    const teamRef = React.useRef();
    const commentRef = React.useRef();
    const statusRef = React.useRef();

    /***
     *     __   __            __   ___ ___       __       
     *    |  \ /  \  |\/|    |__) |__   |  |  | |__) |\ | 
     *    |__/ \__/  |  |    |  \ |___  |  \__/ |  \ | \| 
     *                                                    
     */

  return (
    <div className="reactGridItem gridItemCustomLook">

      <Popup open={githubEditorVisible} closeOnDocumentClick onClose={setGithubEditorVisible}>
            <div className="modal">
                  <div className="header">User Editor </div>
                  
                  <div className="content">
                      
                      <div className="ticketModalStyle">
                        {/*
                        <div className="ticketModalStaticContent">
                          Lorem Ipsum
                        </div>
                        */}

                        <label>LDAP Login:</label><br></br>
                        <input className="ticketModalInput"
                          ref={ldap_loginRef}
                          type="text"
                          name="ldap_login"
                          defaultValue={githubUserData.ldap_login}
                        /><br/><br/><br></br>

                        <label>Github Login:</label><br></br>
                        <input className="ticketModalInput"
                          ref={githubRef}
                          type="text"
                          name="github_login"
                          defaultValue={githubUserData.github_login}
                        /><br/><br/><br></br>

                        <label>Vorname:</label><br></br>
                        <input className="ticketModalInput"
                          ref={given_nameRef}
                          type="text"
                          name="given_name"
                          defaultValue={githubUserData.given_name}
                        /><br/><br/><br></br>                

                        <label>Nachname:</label><br></br>
                        <input className="ticketModalInput"
                          ref={surnameRef}
                          type="text"
                          name="surname"
                          defaultValue={githubUserData.surname}
                        /><br/><br/><br></br>                                

                        <label>E-Mail:</label><br></br>
                        <input className="ticketModalInput"
                          ref={mailRef}
                          type="text"
                          name="mail"
                          defaultValue={githubUserData.mail}
                        /><br/><br/><br></br>                         
                        
                        <label>Zugehörigkeit:</label><br></br>
                        <select className="ticketModalSelect" ref={teamRef} name="team" defaultValue={githubUserData.team}>
                          <option value="intern">intern</option>
                          <option value="extern">extern</option>
                        </select><br/><br/><br></br> 

                        <label>Status:</label><br></br>
                        <select className="ticketModalSelect" ref={statusRef} name="team" defaultValue={githubUserData.status}>
                          <option value="offen">offen</option>
                          <option value="active">active</option>
                          <option value="check">check</option>
                        </select><br/><br/><br></br> 

                        <label>Bemerkung:</label><br></br>
                        <input className="ticketModalInput"
                          ref={commentRef}
                          type="text"
                          name="comment"
                          defaultValue={githubUserData.comment}
                        /><br/><br/><br></br>

                      </div>
                      
                  </div>
                  

                  <div className="actions">
                    <button
                      className="button"
                      onClick={() => {

                        // Define the regular expression for email validation
                        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                        const emailInput = mailRef.current.value;

                        //if(githubRef.current.value !== "" && given_nameRef.current.value !== "" && surnameRef.current.value !== "" && mailRef.current.value !== "" && teamRef.current.value !== "" && emailRegex.test(emailInput))
                        if (
                          githubRef.current.value !== "" &&
                          given_nameRef.current.value !== "" &&
                          surnameRef.current.value !== "" &&
                          teamRef.current.value !== "" &&
                          (
                            ldap_loginRef.current.value !== "" ||
                            (mailRef.current.value !== "" && emailRegex.test(mailRef.current.value))
                          )
                        )
                        {
                          props.handleNotify({type: 'info', message: "Der User wird gespeichert. Bitte warten..."})
                          saveGithubUser();
                        } else {
                          if(githubRef.current.value === ""){
                            props.handleNotify({type: 'alert', message: "Das Github Login Feld darf nicht leer sein!"})
                          }else if(given_nameRef.current.value === ""){
                            props.handleNotify({type: 'alert', message: "Das Vorname Feld darf nicht leer sein!"})
                          }else if(surnameRef.current.value === ""){
                            props.handleNotify({type: 'alert', message: "Das Nachname Feld darf nicht leer sein!"})
                          }else if(teamRef.current.value === ""){
                            props.handleNotify({type: 'alert', message: "Das Zugehörigkeit Feld darf nicht leer sein!"})
                          }else if(ldap_loginRef.current.value === "" && mailRef.current.value === ""){
                            props.handleNotify({type: 'alert', message: "Es muss mindestens ein LDAP Login oder eine gültige E-Mail Adresse angegeben werden!"})
                          }else if(ldap_loginRef.current.value !== "" && emailRegex.test(emailInput)){
                            props.handleNotify({type: 'alert', message: "Es muss eine gültige E-Mail Adresse angegeben werden!"})
                          }else if(emailInput !== "" && !emailRegex.test(emailInput)){
                            props.handleNotify({type: 'alert', message: "Bitte eine gültige E-Mail Adresse angeben!"})
                          }                         
                        }
                      }}
                    >
                      SPEICHERN
                    </button>
                    <button
                      className="button"
                      onClick={() => {
                        setGithubEditorVisible(false)
                      }}
                    >
                      ABBRECHEN
                    </button>
                  </div>
                </div>
      </Popup> 



      <div className="githubWidget--HeaderWrap drag-handle">
        <div className="githubWidgetHeader--Title">Userverwaltung</div>
        <div className="githubWidgetHeader--AddNewBTN" onClick={() => {githubUserEditor('new')}}>+ ADD USER</div>
      </div>
      <div className="githubWidget--ContentWrap">
        {(() => {
          if (isLoaded === false)
            return (
              <>
                <div className="githubWidget--LoadingWrap">
                  <p className="githubWidget--LoadingWrapText">Lade Daten</p>
                  <p>
                    <PropagateLoader color={"#6f7277"} size={4} />
                  </p>
                </div>
              </>
            );
          if (hasError === true)
            return <div className="githubWidget--ErrorWrap">Die GitHub user Daten konnten nicht geladen werden.</div>;
          else
            return (
              <div className="githubWidget-wrapper">
                <Table data={githubData} />
              </div>
            );
        })()}
      </div>
    
    </div>
  );
};


export default GithubUser;
