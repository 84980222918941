import React, { memo } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'react-tooltip';
import "../styles/Header.css"


/**
 * Seiten Header Funktionen 
 * @param {*} props 
 * @returns 
 */
const Header = (props) => {    

    /***
     *     __   __            __   ___ ___       __       
     *    |  \ /  \  |\/|    |__) |__   |  |  | |__) |\ | 
     *    |__/ \__/  |  |    |  \ |___  |  \__/ |  \ | \| 
     *                                                    
     */    

    return (
        <header className="headerWrap">
            <div className="header--DashboardInfo--Wrap">
                {/*<button onClick={props.handleHover} className="header--DashboardInfo--Btn"><FontAwesomeIcon icon="fa-solid fa-bars" /></button>*/}
                <div className="header--DashboardInfo--DashboardTitle">{props.headerData.title}</div>
                <div className="header--DashboardInfo--DashboardDesc">{props.headerData.description}</div>
                {props.unsavedChanges ? 
                    <button className="header--DashboardInfo--Btn" onClick={() => props.handleSaveDashboard()}><FontAwesomeIcon icon="fa-regular fa-floppy-disk" /></button>                     
                : null}
            </div>
            <div className="header--User--Wrap">            
                {/*<div className="header--User--Icon"><FontAwesomeIcon icon="fa-solid fa-user" /></div>*/}
                <div className="header--User--Name">{props.userName}</div>
                <button id={"feedbackBTN"} className="header--User--Btn" onClick={() => window.location.href = 'mailto:it.infra.op.dash@freenet.ag?subject=Github%20Tools%20Feedback'}><FontAwesomeIcon icon="fa-regular fa-message" /></button>
                <Tooltip    
                    className="tooltipStyles"
                    anchorSelect={"#feedbackBTN"} 
                    html={"Feedback senden"} 
                    place="bottom" 
                    style={{ backgroundColor: "var(--hover-color)", color: "var(--text-color)", zIndex: "500" }}
                />                
                <div></div>
            </div>
        </header>
    )
}

export default memo(Header)