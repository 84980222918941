import axios from "axios";

const BACKEND_SVC = process.env.REACT_APP_BACKEND_SVC;

export const githubuserAPI = axios.create({
  baseURL: `${BACKEND_SVC}`,
  headers: {
    "Content-type": "application/json"
  }
});

