import React, { useCallback, memo, useEffect, useMemo } from "react";
import '../styles/MainMenu.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { baseBlade } from "../images/blade";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

/**
 * Steuerung des Hauptmenüs, sowie Bereitstellung alles Menü-Funktionen
 * @param {*} props 
 * @returns 
 */
const MainMenu = (props) => {
    //console.log("Menu rerender")
    /***
     *     __  ___      ___  ___  __  
     *    /__`  |   /\   |  |__  /__` 
     *    .__/  |  /~~\  |  |___ .__/ 
     *                                
     */

    const [availableDashboards,setAvailableDashboards] = React.useState([{name: "Github Userverwaltung", _id: "63da2e37814fe3b185af2ca4"}]);

    const menuWrapperClass = props.isShown ? "MainMenuWrapperHover" : "MainMenuWrapper"

    /***
     *     ___            __  ___    __        __  
     *    |__  |  | |\ | /  `  |  | /  \ |\ | /__` 
     *    |    \__/ | \| \__,  |  | \__/ | \| .__/ 
     *                                             
     */


    const updateAvailableDashboards = useEffect(() => {
    
        //console.log("Props availableDashboards:")
        //console.log(props.availableDashboards)
        if(props.availableDashboards !== undefined){
            setAvailableDashboards(props.availableDashboards)
        }        
        
    },[props.availableDashboards]);

    /**
     * Funktion welche dafuer sorgt, dass das Menu bei dem Betreten mit der Maus
     * aufgeklappt wird.
     */
    const hoverHandler = () => {
        props.handleHover(true);
    }
    
    /**
     * Funktion welche dafuer sorgt, dass das Menu bei dem verlassen mit der Maus
     * wieder eingefahren wird
     */
    const outHandler = () => {
        props.handleHover(false);
    }

    /**
     * Funktion welche einen Link in einem neuen Tab oeffnet
     * Der Link, welcher geoeffnet werden soll wird mitgegeben
     * @param {*} link 
     */
    const openLink = (link) => {
        window.open(link,"_blank");
    }

    /**
     * Funktion welche den Klick auf ein Dashboard verarbeitet
     * Es werden die Dashboardinformationen mitgegeben
     * @param {*} dashboard 
     */
    const openDashboard = (dashboard) => {
        props.handleNotify({type: 'success', message: 'Dashboard:'+ dashboard.name + ' geladen'})
    }

    /***
     *     __   __            __   ___ ___       __       
     *    |  \ /  \  |\/|    |__) |__   |  |  | |__) |\ | 
     *    |__/ \__/  |  |    |  \ |___  |  \__/ |  \ | \| 
     *                                                    
     */

    //console.log("Menu re-rendered!")

    return(
        <div    
            onMouseEnter={hoverHandler}
            onMouseLeave={outHandler}
            className={menuWrapperClass}>
            {/*className="MainMenuWrapperHover">*/}
            <div className="MainMenuContentrHover">
                {/* Header Element mit Ueberschrift und Blade Logo */}
                <div className="MainMenuHeader">
                    <div className="MenuHeader--Icon"><img src={baseBlade} alt="" width="45" heigt="45"></img></div>
                    <span className="MenuHeader--Text">DASH Tool Hub</span>
                </div>
                <div className="MainMenueContent--Wrapper">
                    {/* Das erste Menuelement mit den Dashboards als Inhalt */}
                    <div className="MainMenuItem">
                        <div className="MainMenuItem--Header" >
                            <div className="MainMenuItem--Icon"><FontAwesomeIcon icon="fa-solid fa-house"/></div>
                            <div className="MainMenuItem--Text">Tools</div>
                        </div>
                        {/* Einbinden der Dashboards */}
                        
                        <div className="MainMenuItem--Content">
                        
                            {availableDashboards.map(dashboard => (
                                //<div key={dashboard.name + dashboard._id} className="MainMenuItemContent--Name" onClick={() => openDashboard(dashboard)}> {dashboard.name} </div>  
                                <div key={dashboard.name + dashboard._id + 'wrap'} className="MainMenuItemContent--DashboardWrapper">
                                    {
                                        dashboard.active === true ? 
                                        (<div key={dashboard.name + dashboard._id} className="MainMenuItemContent--DashboardNameActive" onClick={() => {props.handleChangeShownDashboard(dashboard._id); outHandler()}}> {dashboard.name} </div>)
                                        :
                                        (<div key={dashboard.name + dashboard._id} className="MainMenuItemContent--DashboardName" onClick={() => {props.handleChangeShownDashboard(dashboard._id); outHandler()}}> {dashboard.name} </div>)
                                    }

                                    
                                </div>
                            ))}
                        
                        </div>
                        
                        {/*<div className="MainMenuContent--Button" onClick={() => createDashboard()}>Dashboard Erstellen</div>*/}
                    </div>
                    
                    <div className="MainMenuItem--Divider"></div>
                    <button className="darkmodeSwitchBTN" onClick={props.handleSwitchTheme}>
                        Switch Darkmode
                    </button>
                </div>
            </div>
            {
            /* 
                Einblenden des Menubuttons jenachdem, ob der State isShow, welcher aus der App.js mit uebergeben wurde,
                gesetzt ist oder nicht
            */
            }
            {!props.isShown ? 
                <div className="MainMenuButton">
                    <div className="MainMenuButton--Text">Auswahl</div>
                </div> : null
            }
        </div>
    )
}

export default memo(MainMenu)